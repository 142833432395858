<h2>Club Championship</h2>
<div class="alert alert-success" role="alert">
    The 2018-19 Reading Joggers Club Championship is now underway. The <a href="https://tinyurl.com/RJ-Champs-2018-19">current
    standings are available here</a>.
</div>
<h4>
    Rules &amp; Format
</h4>
<ul>
    <li>To qualify you need to run at least one race at each distance (5 Miles, 10K, 10 Miles, Half Marathon)
    </li>
    <li>If you run more than one race at the same distance i.e. two 10Ks the race with the highest points will
        count towards your championship score
    </li>
    <li>You’re competing against members in your own age category
    </li>
    <li>Men: Senior (under 40), M40-49, M50-59, M60-69, M70+
    </li>
    <li>Ladies: Senior (under 35), F35-44, F45-54, F55-64, F65+
    </li>
    <li>Your Championship age category is determined by your age on 1st April (the start of club championship)
    </li>
    <li>Within each age category, the fastest finisher scores 50 points, the next fastest 49 points, and so on
    </li>
    <li>For your race result to qualify for the Championship you must run in <a routerLink="/kit">club vest/t-shirt</a>
    </li>
    <li>Times will be based on individual chip times rather than gun times, where provided</li>
</ul>

<h4>Races</h4>

<ul>
    <li>5 Miles
        <ul>
            <li><a href="http://www.handycrossrunners.co.uk/Marlow5.html">Marlow 5</a> - 13th May  2018</li>
            <li><a href="https://bfrraces.weebly.com/forest-5.html">Forest 5</a> - 20th June 2018</li>
            <li><a routerLink="/events/summer-handicap">Reading Joggers Summer Handicap</a> - 16th August 2018</li>
        </ul>
    </li>


    <li>10K
        <ul>
            <li><a href="http://www.royalberks10k.com/">GreenPark Royal Berkshire 10K</a> - 20th May 2018</li>
            <li><a href="https://www.barnesfitness.co.uk/thames-10km-5km-3km-beale-park/">Thames 10KM at Beale Park</a> - 5th July 2018</li>
            <li><a href="http://www.woodcote10k.org.uk/">Woodcote 10K</a> - 6th January 2019</li>
        </ul>
    </li>

    <li>10 Miles
        <ul>
            <li><a href="https://www.tadleyrunners.co.uk/">Tadley 10 Mile</a> - 14th October 2018</li>
            <li><a href="https://www.mysportingtimes.com/">Gutbuster</a> - 30th December 2018</li>
            <li><a href="https://www.maidenheadac.org/easter-10/">Maidenhead Easter 10</a> - 19th April 2019</li>
        </ul>
    </li>

    <li>Half Marathon
        <ul>
            <li><a href="https://www.purplepatchrunning.com/">Maidenhead Half Marathon</a> - 2nd September 2018</li>
            <li><a href="http://www.wokinghamhalfmarathon.co.uk/">Wokingham Half Marathon</a> - 24th February 2019</li>
            <li><a href="http://www.readinghalfmarathon.com/">Reading Half Marathon</a> - 17th March 2019</li>
        </ul>
    </li>

</ul>