import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-locations',
  templateUrl: './other-locations.component.html'
})
export class OtherLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
