<h2>Summer Handicap</h2>

<div class="alert alert-warning" role="alert">
  <p>
    England Athletics have
    <a
      href="https://www.englandathletics.org/athletics-and-running/news/guidance-update-for-restricted-return-to-activity-for-coaches-leaders-athletes-runners-and-facilities-step-3/"
      >updated their guidance on COVID-19</a
    >.
  </p>
  <p>
    Following this we have restarted Tuesday social runs and Thursday training.
    EA guidance will be followed at all times. At present only members can run
    and train with the club and we may need to restrict the number of members
    running or training if the recommendations cannot be achieved.
  </p>
  <p>The Committee will keep you up to date via Facebook and email.</p>
</div>
<!--
<div class="alert alert-info" role="alert">
  The 34th Reading Joggers Summer Handicap Series will soon be underway.
</div>


<p>
  We meet at the main car park in
  <a routerLink="/find-us/prospect-park">Prospect Park</a>, at 7:00pm. Our aim is to get the first runners away by 7:20pm.
</p>

<h4>Schedule</h4>

<p>
    We have tried to avoid local events, including the Dinton Pastures 5/10K and
    the Thames 10K events, which now take place on Thursdays.
  </p>

<ul>
  <li>Summer Handicap 1 (2.5 miles) - Thursday 9th April 2020</li>
  <li>Summer Handicap 2 (5 miles) - Thursday 30th April 2020</li>
  <li>Summer Handicap 3 (5 miles) - Thursday 21st May 2020</li>
  <li>Summer Handicap 4 (5 miles) - Thursday 18th June 2020</li>
  <li>Summer Handicap 5 (5 miles) - Thursday 2nd July 2020</li>
  <li>Summer Handicap 6 (5 miles) - Thursday 23rd July 2020</li>
  <li>Summer Handicap 7 (5 miles) - Thursday 13th August 2020</li>
  <li>Summer Handicap 8 (2.5 miles) - Thursday 3rd September 2020</li>
</ul>

<h4>How it works</h4>

<p>
  You need to run a minimum of <strong>four</strong> races as your best four results will count
  towards your final points total.
</p>

<p>
  If you have <strong>not</strong> taken part in the Handicap Series before <em>or</em> if you have not
  completed a race during the 2019 series you will have to complete <strong>five</strong> races. The extra race is needed to work out your handicap.
</p>

<p>
  We will start you off in two minute intervals and ask that you are ready to
  start when your time comes around. Please do not ask to start in another 2
  minute group as it will make our life a lot easier and less likely that you are
  given the wrong time at the end.
</p>

<p>Points are awarded as follows:</p>
<ul>
  <li>1 point for first place</li>
  <li>2 points for second place</li>
  <li>3 points for third place</li>
  <li>and so on...</li>
</ul>
<p>
  Thus if there are 25 members taking part, the last place will score 25
  points.
</p>
<p>
  So the winner at the end of the season will be the member with the lowest
  number of points who has completed the required number of races, (4 if you ran last season or 5
  if you did not).
</p>

<p>
  Please note that MEMBERS ONLY will be awarded points, but guests will be
  welcome to take part, and they will be included in the results as guest
  runners.
</p>

<p>PLEASE BE CAREFUL AND NOTE THAT YOU TAKE PART ENTIRELY AT YOUR OWN RISK</p>

<p>
  After the Handicap some of the members visit the Mansion House to replace some
  lost fluid.
</p>

<h4>Course</h4>

<p>
  The course is accurately measured and comprises of one lap for the 2.5 miles
  and two laps for the 5 miles.
</p>
<p>The course is also clearly marked with green spots and arrows.</p>

<p>
  The course uses the paths within Prospect Park and also the pavements on the outside of the park. You will
have to cross two side roads off Leibenrood Road on each lap. You will also have to use the main road up
to the Mansion House within the park. On the five mile course you will have to cross this road, but there is
a zebra crossing. There will be warning signs placed on this road and near the entrance to Prospect Park.
</p>

<p>
  <a href="https://www.strava.com/segments/12125957"
    >Summer Handicap Strava segment (distance approximate)</a
  >
</p> -->
