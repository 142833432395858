<div class="row pt-3 mt-3">
  <div class="col">
    <img src="../../images/banner_bottom.jpg" class="mw-100" />
  </div>
</div>
<div class="row">
  <div class="col">
    <footer>
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <span class="navbar-text">
          &copy; 2020 Reading Joggers&nbsp;|&nbsp;<a
            href="doc/Reading_Joggers_Privacy_Notice.pdf"
            >Privacy Notice</a
          >&nbsp;|&nbsp;Follow&nbsp;us:&nbsp;
        </span>
        <div>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a href="https://twitter.com/ReadingJoggers"
                ><img src="images/social_tw_black.png" alt="twitter"
              /></a>
            </li>
            <li class="nav-item">
              <a href="https://www.facebook.com/groups/ReadingJoggers"
                ><img src="images/social_fb_black.png" alt="facebook"
              /></a>
            </li>
            <li class="nav-item">
              <a href="https://www.flickr.com/groups/readingjoggers"
                ><img src="images/social_fl_black.png" alt="flickr"
              /></a>
            </li>
            <li class="nav-item">
              <a href="https://www.instagram.com/readingjoggers/"
                ><img src="images/social_in_black.png" alt="instagram"
              /></a>
            </li>
            <li class="nav-item">
              <a href="mailto:info@readingjoggers.org"
                ><img src="images/social_em_black.png" alt="email us"
              /></a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>
</div>
