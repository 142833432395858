<h2>Club Kit</h2>
<p>Club colours/kit must be worn when you compete at:</p>
<ul>
  <li>Any of the races forming our Club Championship</li>
  <li>At any event where we compete as a team</li>
</ul>
<p>
  Most races organised under England Athletics (EA) rules include a discount for
  EA afflilated club. Therefore we strongly encourage members to wear club
  colours/kit during races.
</p>
<p>
  Wearing RJ colours allows you to spot other RJ membes and also helps build a
  good club spirit. Its also acts as great advertising for prospective new
  members.
</p>
<p>
  Please fill in the
  <a href="https://tinyurl.com/RJ-Kit-Order">online order form</a> if you wish
  to place an order.
</p>
<p>
  It's recommended you try your size before you place your order. Order once
  placed cannot be exchanged for different size.
</p>
<p>
  Kit is ordered when we have enough items to place an order with our supplier.
  Please keep an eye out on facebook for advance warning when orders are going
  to be placed.
</p>

<p>
  <a
    href="https://tinyurl.com/RJ-Kit-Order"
    class="btn btn-primary btn-lg"
    role="button"
    >Order Online</a
  >
</p>
<h3>Technical t-shirts and vests</h3>
<img src="images/gallery/k001.jpg" alt="RJ Vest & T-shirt" class="mw-100" />
<h3>Hoodies and fleeces</h3>
<p><img src="images/gallery/k002.jpg" class="mw-100" /></p>
<p><img src="images/gallery/k003.jpg" class="mw-100" /></p>