<h2>Beansheaf Community Centre</h2>
<p>We meet here for many of our club runs and some training sessions. In good weather we often meet just outside the
  building but in poor weather we use the room we have booked upstairs. Toilet facilities are available.</p>
<p>Please see the <a routerLink="/events">events</a> pages for more information on upcoming events.</p>
<h4>Address</h4>
<p>Beansheaf Community Centre<br />
  Charrington Road<br />
  Calcot<br />
  Reading<br />
  RG31 7AW</p>
<h4>Map</h4>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.086683906741!2d-1.0528489484842527!3d51.43820192348095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876995b2055a6c3%3A0x5e5d81beae0b0b3b!2sBeansheaf+Community+Centre!5e0!3m2!1sen!2suk!4v1539545062946" class="w-100" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>