import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-events',
  templateUrl: './social-events.component.html'
})
export class SocialEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
