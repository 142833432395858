<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="beansheaf"><small>Beansheaf</small></a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="prospect-park"
          ><small>Prospect Park</small></a
        >
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="lansdowne-road"
          ><small>Lansdowne Road / Grafton Road Circuit</small></a
        >
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="other-locations"
          ><small>Other Locations</small></a
        >
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>
