<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/about"><small>About Us</small></a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="committee"><small>The Committee</small></a>
            </li>
          <li class="nav-item">
            <a class="nav-link" href="/doc/Reading_Joggers_Club_Constitution.pdf"><small>Club Constitution</small></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/doc/Reading_Joggers_Rules_and_regulations.pdf"><small>Rules and Regulations</small></a>
          </li>
        </ul>
    </div>
</nav>
<router-outlet></router-outlet>
