import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prospect-park',
  templateUrl: './prospect-park.component.html'
})
export class ProspectParkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
