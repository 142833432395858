<div class="container">
  <div class="row">
    <div class="col">
      <h1 class="text-center align-top text-uppercase float-left"><a routerLink="/">Reading&nbsp;Joggers</a></h1>
      <span class="d-none d-lg-block"><img style="height: 100px" class="float-right pt-3" src="../images/abt.jpg" alt="Reading Joggers logo"></span>
    </div>
  </div>
  <navigation></navigation>
  <div class="row">
    <div class="col" style="min-height:0">
      <app-image-banner></app-image-banner>
    </div>
  </div>
  <router-outlet></router-outlet>
  <app-footer></app-footer> 
</div>
