<h2>About Us</h2>
<p>Reading Joggers is a small and friendly running club, formed in 1977 during the early years of the first
    jogging boom. We have about 150 active members ranging in age from 18 to 80. Just come along to the Beansheaf
    Community Centre, Charrington Road, Calcot, Reading RG31 7AW on a Tuesday or Thursday evening at 7:00
    PM where a warm welcome awaits you. We aim to leave for our runs about 7:20 PM.</p>
<p>Running keeps you fit, but there's no pressure from us to improve! It's sheer enjoyment that keeps us
    going! However we have summer and winter handicaps where we try ourselves out over a regular course and
    a club championship for those who do wish to improve and for those that do we have a qualified UKA coach
    who runs coaching sessions and can offer guidance.</p>
<p>The club is affiliated to <a href="http://www.englandathletics.org/" target="_blank">England Athletics</a>,
    so your annual subscription (currently £26 of which £15 goes to England Athletics Registration) offers
    reduced cost of entry to many running events.</p>
<p>If you like running with us then please complete the <a href="https://tinyurl.com/RJ-Subscribe">online
        club membership form</a></p>
<p>If you'd like to know more just <a routerLink="contact">contact us</a>.</p>
<h3>Activities</h3>
<ul>
    <li><a routerLink="events">Club runs</a> every Tuesday at 7:20pm</li>
    <li><a routerLink="events">Training sessions</a> on Thursday at 7:20 pm</li>
    <li>Social runs arranged on most weekends</li>
    <li><a routerLink="/events/summer-handicap">Summer Handicap</a> & <a routerLink="/events/winter-handicap">Winter
        Handicap</a> competitions</li>
    <li>Participation in local running events</li>
</ul>