<h2>Club Runs &amp; Training</h2>
<div class="alert alert-warning" role="alert">
  <p>
    England Athletics have
    <a
      href="https://www.englandathletics.org/athletics-and-running/news/guidance-update-for-restricted-return-to-activity-for-coaches-leaders-athletes-runners-and-facilities-step-3/"
      >updated their guidance on COVID-19</a
    >.
  </p>
  <p>
    Following this we have restarted Tuesday social runs and Thursday training.
    EA guidance will be followed at all times. At present only members can run
    and train with the club and we may need to restrict the number of members
    running or training if the recommendations cannot be achieved.
  </p>
  <p>The Committee will keep you up to date via Facebook and email.</p>
</div>
<event-item *ngFor="let event of events" [event]="event"></event-item>
