<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="club-runs-and-training"><small>Club
          Runs &amp; Training</small></a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="summer-handicap" routerLinkActive="active"><small>Summer
          Handicap</small></a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="winter-handicap" routerLinkActive="active"><small>Winter
          Handicap</small></a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="social-events" routerLinkActive="active"><small>Social
          Events</small></a>
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>