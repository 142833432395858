<div class="row">

        <div class="col">

                <h2>Gallery</h2>
                <h3>External Sites</h3>

                <div class="card-columns">

                        <div class="card">
                                <a href="https://www.flickr.com/groups/readingjoggers/" target="_blank"><img class="card-img-top" src="images/gallery/g000.jpg" alt="Reading Joggers on flickr"></a>
                                <div class="card-body">
                                        <p class="card-text"><a href="https://www.flickr.com/groups/readingjoggers/">Reading Joggers on flickr</a></p>
                                </div>
                        </div>

                        <div class="card">
                                        <a href="https://www.facebook.com/groups/ReadingJoggers/photos/" target="_blank"><img class="card-img-top" src="images/gallery/g007.jpg" alt="Reading Joggers on Facebook"></a>
                                        <div class="card-body">
                                                <p class="card-text"><a href="https://www.facebook.com/groups/ReadingJoggers/photos/">Reading Joggers on Facebook</a></p>
                                        </div>
                                </div>


                </div>
                <h3>Club Photos</h3>
                        <div class="card-columns">

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g006.jpg" alt="Endure24 (Jun  2016)">
                                <div class="card-body">
                                        <p class="card-text">Endure24 (June 2016)</p>
                                </div>
                        </div>
                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g010.jpg" alt="Margaret celebrating her 250th parkrun (March 2016)">
                                <div class="card-body">
                                        <p class="card-text">Margaret celebrating her 250th parkrun (March 2016)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g004.jpg" alt="Members with their new kit (March 2016)">
                                <div class="card-body">
                                        <p class="card-text">Members with their new kit (March 2016)</p>
                                </div>
                        </div>
                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g002.jpg" alt="Reading Joggers take over parkrun (June 2015)">
                                <div class="card-body">
                                        <p class="card-text">Reading Joggers take over parkrun (June 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g001.jpg" alt="Reading Round Relay (September 2015)">
                                <div class="card-body">
                                        <p class="card-text">Reading Round Relay (September 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g003.jpg" alt="Snow White and the Seven Dwarves at Endure 24 (June 2015)">
                                <div class="card-body">
                                        <p class="card-text">Snow White and the Seven Dwarves at Endure 24 (June 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g007.jpg" alt="parkrun (2014)">
                                <div class="card-body">
                                        <p class="card-text">parkrun (2014)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g009.jpg" alt="Shinfield 10K (4th July 2015)">
                                <div class="card-body">
                                        <p class="card-text">Shinfield 10K (4th July 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g012.jpg" alt="Reading Joggers Christmas Outrun (December 2015)">
                                <div class="card-body">
                                        <p class="card-text">Reading Joggers Christmas Outrun (December 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g008.jpg" alt="Round Reading Relay (September 2015)">
                                <div class="card-body">
                                        <p class="card-text">Round Reading Relay (September 2015)</p>
                                </div>
                        </div>

                        <div class="card">
                                <img class="card-img-top" src="images/gallery/g011.jpg" alt="Reading Joggers Fast Five - Winners of Endure 24 2015 (male team of 5 category)">
                                <div class="card-body">
                                        <p class="card-text">Reading Joggers Fast Five - Winners of Endure 24 2015
                                                (male team of 5 category)</p>
                                </div>
                        </div>

                </div>

        </div>

</div>