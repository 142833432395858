<h2>Social Events</h2>
<div class="alert alert-warning" role="alert">
  <p>
    England Athletics have
    <a
      href="https://www.englandathletics.org/athletics-and-running/news/guidance-update-for-restricted-return-to-activity-for-coaches-leaders-athletes-runners-and-facilities-step-3/"
      >updated their guidance on COVID-19</a
    >.
  </p>
  <p>
    Following this we have restarted Tuesday social runs and Thursday training.
    EA guidance will be followed at all times. At present only members can run
    and train with the club and we may need to restrict the number of members
    running or training if the recommendations cannot be achieved.
  </p>
  <p>The Committee will keep you up to date via Facebook and email.</p>
</div>
<!-- <div class="card">
  <div class="card-body">
    <h5 class="card-title">AGM @ The Mansion House, Prospect Park</h5>
    <h6 class="card-subtitle mb-2 text-muted">Wednesday 10th April 2019 at 7pm</h6>
    <p class="card-text">Club AGM at the Mansion House. Arrive 7pm for 7.30pm. Coffee available, drinks in the bar afterwards.</p>
    <p class="card-text">See the <a href="https://www.facebook.com/events/223338441945670/">Facebook event</a> for more details.</p>
  </div>
</div> -->

<!-- <div class="card">
  <div class="card-body">
    <h5 class="card-title">Post Thames 10k Drinks @ The Swan, Pangbourne</h5>
    <h6 class="card-subtitle mb-2 text-muted">Thursday 4th July 2019 at 7pm</h6>
    <p class="card-text">See the <a href="https://www.facebook.com/events/251154732444620/">Facebook event</a> for more details.</p>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">RJ Summer BBQ</h5>
    <h6 class="card-subtitle mb-2 text-muted">Sunday 14th July 2019 at 12:30pm</h6>
    <p class="card-text">See the <a href="https://www.facebook.com/events/430778317673176/">Facebook event</a> for more details.</p>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">Round Reading Relay</h5>
    <h6 class="card-subtitle mb-2 text-muted">September 2019 (TBC)</h6>
    <p class="card-text">Details to follow.</p>
  </div>
</div> -->

<!-- <div class="card">
  <div class="card-body">
    <h5 class="card-title">Christmas Meal</h5>
    <h6 class="card-subtitle mb-2 text-muted">Saturday 7th December 2019 at 6pm (TBC)</h6>
    <p class="card-text">See the <a href="https://www.facebook.com/events/237794440494111/">Facebook event</a> for more details.</p>
  </div>
</div> -->
