import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kit',
  templateUrl: './kit.component.html'
})
export class KitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
