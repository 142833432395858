<h2>Winter Handicap</h2>
<!-- <div class="alert alert-success" role="alert">The 29th season (2019-2020) of the Reading Joggers Winter Handicap is now
  underway.</div> -->
<div class="alert alert-warning" role="alert">
  <p>
    England Athletics have
    <a
      href="https://www.englandathletics.org/athletics-and-running/news/guidance-update-for-restricted-return-to-activity-for-coaches-leaders-athletes-runners-and-facilities-step-3/"
      >updated their guidance on COVID-19</a
    >.
  </p>
  <p>
    Following this we have restarted Tuesday social runs and Thursday training.
    EA guidance will be followed at all times. At present only members can run
    and train with the club and we may need to restrict the number of members
    running or training if the recommendations cannot be achieved.
  </p>
  <p>The Committee will keep you up to date via Facebook and email.</p>
</div>
<p>
  Following on from the success of the Half Marathon Social Run, I am pleased to
  announce that the Winter Handicap will be running again.
</p>

<p>
  This is a set of 6 runs over winter on the same mixed trail/road course, each
  runner is assigned a handicap and the idea is to beat it. Your handicap is
  then adjusted for the next event. You have to do 3 or 4 (if new to the event)
  runs to be eligible for the prize. Sadly last years event was truncated due to
  Covid and the final run was cancelled leaving a lot of runners ineligible for
  the points, therefore I will roll over the prizes!
</p>

<h4>Dates</h4>

<p>
  <strong>This will be on Sunday mornings with the following dates (provisional after
  the 1st event). Start is Pangbourne Rec RG8 7BY</strong>
</p>

<ul>
  <li>Saturday 24th October - 9.30 am Familiarization run</li>
  <li>Sunday 25th October - 9.30 am Familiarization run</li>
  <li>WH1 Sunday 1st November - 1st runners away at 9.20 am</li>
  <li>WH2 22/11/20</li>
  <li>WH3 6/12/20</li>
  <li>WH4 17/1/21</li>
  <li>WH5 21/2/21</li>
  <li>WH6 14/3/21</li>
</ul>

<p>
  <strong>The 25th October will be a optional familiarization run on an unmarked course.
  Please can I have some experienced runners to create facebook events (groups
  of 6) to guide new runners round. Pace will be that of slowest person.</strong>
</p>

<h4>Course</h4>

<p>
  The course will be the same as last year, with the minor change that we will
  be using the 'wet weather' version from the start (avoids the Thames Path
  which gets very wet)
</p>

<p>
  This is a lovely 7 mile route starting from Pangbourne Rec, heading up to
  Purley, followed by a lap of Sulham Woods and a fast downhill finish. Trail
  shoes advised.
</p>

<p>
  <a href="https://www.plotaroute.com/route/1295762"
    >https://www.plotaroute.com/route/1295762</a
  >
</p>

<p>The course will be fully marked with flour and maps will be available</p>

<p>
  There will be a few changes made to make the event covid secure and following
  feedback from last years event.
</p>

<ul>
  <li>
    All competitors will have to sign up to Facebook events for each race (or
    email the club beforehand)
  </li>
  <li>
    Rough start times will be posted before each event so runners can time their
    arrival.
  </li>
  <li>Starting groups will be limited to 6, set off at intervals as usual</li>
  <li>
    All competitors must run with a mobile phone, there will not be marshals on
    the course. there will be a back marker.
  </li>
</ul>

<h4>Volunteers</h4>

<p>
  This event can only happen with the help of some volunteers to mark the course
  and or timekeep. I have a markers guide with lots of photos!
</p>

<p>The jobs each week are:</p>

<ul>
  <li>
    Marking section 1: approx 2 mile linear stretch from Pangbourne to Purley, 4
    bags of flour
  </li>
  <li>
    Marking section 2: Approx 2 mile loop in Sulham woods - 6! bags of flour
  </li>
  <li>Sweeper</li>
  <li>Timekeeper/coordinator</li>
</ul>

<p>
  The marking jobs require you to be done by 9.20 am at the latest. There are no
  problems with marking and running if you are an early bird.
</p>

<p>
  Hopefully this all makes sense! - please catch me on a Tue/Thur if any
  questions
</p>

<p>
  Please save the dates and have a think about volunteering for one of the
  events.
</p>

<p>Thanks</p>

<p>Mike</p>
