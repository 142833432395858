import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-us',
  templateUrl: './find-us.component.html'
})
export class FindUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
