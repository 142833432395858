<div class="row">
  <div class="col">
    <div class="jumbotron">
      <div class="row">
        <div class="col-xs-12 col-md-9">
          <h2 class="display-4 text-uppercase">Welcome to Reading Joggers</h2>
          <div class="alert alert-warning" role="alert">
            <p>
              England Athletics have
              <a
                href="https://www.englandathletics.org/athletics-and-running/news/guidance-update-for-restricted-return-to-activity-for-coaches-leaders-athletes-runners-and-facilities-step-3/"
                >updated their guidance on COVID-19</a
              >.
            </p>
            <p>
              Following this we have restarted Tuesday social runs and Thursday
              training. EA guidance will be followed at all times. At present
              only members can run and train with the club and we may need to
              restrict the number of members running or training if the
              recommendations cannot be achieved.
            </p>
            <p>
              The Committee will keep you up to date via Facebook and email.
            </p>
          </div>
          <p class="lead">
            Just starting to run? Tired of running on your own? Preparing for a
            race? Whatever your ability, if you are looking for a friendly,
            local running club, we could be just what you are looking for. All
            ages and abilities are welcome.
          </p>
          <p>
            Look around our website, or just come along to one of our regular
            <a routerLink="/events">club runs and training sessions</a> where a
            warm welcome awaits you.
          </p>
        </div>
        <div class="col-xs-12 col-md-3">
          <p>Reading Joggers - <strong>running since 1977</strong></p>
          <p>
            The club is affiliated to
            <a href="https://www.englandathletics.org/">England Athletics</a>
          </p>
          <p>
            <a routerLink="/events">Club runs/training</a> every Tuesday and
            Thursday at 7pm
          </p>
          <p>Discounts at local sports shops</p>
          <p>
            <a routerLink="/events">Club Handicap</a> races organised throughout
            the year
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <img class="card-img-top" src="images/b1.jpg" />
        <div class="card-body">
          <h5 class="card-title"><a routerLink="/about">About Us</a></h5>
          <p class="card-text">
            Reading Joggers is a small and friendly running club. We have about
            150 active members ranging in age from 18 to 80+
          </p>
        </div>
        <div class="card-footer">
          <a routerLink="/about" class="btn btn-primary">Read more</a>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="images/b2.jpg" />
        <div class="card-body">
          <h5 class="card-title"><a routerLink="/events">Events</a></h5>
          <p class="card-text">
            Club runs, Training, Summer & Winter Handicap, Club Championsihp
          </p>
        </div>
        <div class="card-footer">
          <a routerLink="/events" class="btn btn-primary">View events</a>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="images/b4.jpg" />
        <div class="card-body">
          <h5 class="card-title"><a routerLink="../contact">Contact Us</a></h5>
          <p class="card-text">
            Get in touch with us by email or online message
          </p>
        </div>
        <div class="card-footer">
          <a routerLink="../contact" class="btn btn-primary">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
