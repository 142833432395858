<h2>Other Locations</h2>
<h3>The Chase</h3>
<p>We meet here for some training sessions. Check the <a routerLink="/events">events</a> pages for details.</p>
<h4>Address</h4>
<p>The Chase, Calcot, Reading, RG31 7DE</p>

<h3>High Street (The Old A4), Theale</h3>
<p>We meet here for some training sessions. Check the <a routerLink="/events">events</a> pages for details.</p>
<h4>Directions</h4>
<p>You can go over the junction 12 motorway roundabout, towards the Theale bypass, and turn right at the next roundabout towards Theale. At the T-junction mini roundabout turn right and park up near the barrier.</p>
<p>If you decide to park at Beansheaf you can then jog to the session site as a warm up. You turn right into Sainsbury's and then left into Pincents Lane, passing Porsche Cars to your right and Dunelm to your left and head towards the footbridge over the motorway.</p>
<h4>Address</h4>
<p><a href="https://goo.gl/maps/tCY9Npw3jVo">High Street, Theale, Reading RG7 5AG</a></p>