<h2>The Committee</h2>
<div class="row pt-3">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/jayne-woodhouse.jpg"
          alt="Jayne Woodhouse"
        />
        <div class="card-body">
          <h5 class="card-title">Chair – Jayne Woodhouse</h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
          <p>
            I joined Reading Joggers in 2012 after reality set in, either diet
            for the rest of my life or move. I hadn’t run for many years. I can
            honestly say that running has given me back my life. I have since
            become a member of the 100marathon club, something I would never
            have imagined in a million years. The passion I have for running is
            immense, I love the wellbeing it gives me, the community spirit it
            provides and helping and inspiring people to achieve goals. I’m
            usually in the slower group but hey I’m out there and totally
            enjoying it.
          </p>
        </div>
      </div>
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/michael-goff.jpg"
          alt="Michael Goff"
        />
        <div class="card-body">
          <h5 class="card-title">Secretary – Michael Goff</h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
          <p>
            My name is Mike Goff and I’ve been a member of Reading Joggers for 4
            years. I run with the club most weeks and take part in many of the
            local races. I regularly run and volunteer at Prospect parkrun.
            Reading Joggers has been brilliant for giving me the motivation and
            knowledge to become a stronger runner and has helped ensure that
            running remains a pleasure not a chore. Since I joined I’ve steadily
            tried to become more actively involved in the club, starting with
            regularly leading one of the Tuesday night groups and culminating in
            become a committee member for the first time this year (2019). I
            hope to carry on the good work of my predecessors and ensure that
            the club remains friendly, welcoming and beneficial to all.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row pt-3">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <!-- <img
          class="card-img-top"
          src="images/committee/mahmud-husein.jpg"
          alt="Mahmud Husein"
        /> -->
        <div class="card-body">
          <h5 class="card-title">Treasurer – Mahmud Husein</h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
        </div>
      </div>
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/nick-steward.jpg"
          alt="Nick Steward"
        />
        <div class="card-body">
          <h5 class="card-title">Club Kit – Nick Steward</h5>
          <p class="card-text">
            <a href="mailto:kit@readingjoggers.org">info@readingjoggers.org</a>
          </p>
          <p>
            Hi, I'm Nick Steward and am responsible for the Reading Joggers club
            kit.
          </p>
          <p>
            I joined the club in 2010 to get fit for the coming hockey season...
            but I ended up staying and haven't played hockey since!
          </p>
          <p>
            I've gone from shorter distances to longer runs which I probably
            enjoy the most. Our club is friendly and all-welcoming. We have
            great coaches and our members range from Team GB ultra-runners, 100
            Marathon Club members to social runners who enjoy a gentle jog
            around Tilehurst and Calcot on a Tuesday evening!
          </p>
          <p>
            Over the years I've probably used nearly every type of running
            garment there is, so hopefully I'm well suited to look after our kit
            and am always happy to give any tips and recommendations!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row pt-3">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/mary-wilson.jpg"
          alt="Mary Wilson"
        />
        <div class="card-body">
          <h5 class="card-title">
            Footprint Editor & Club Welfare Officer – Mary Wilson
          </h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
        </div>
      </div>
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/james-greaves.jpg"
          alt="James Greaves"
        />
        <div class="card-body">
          <h5 class="card-title">Club Captain – James Greaves</h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row pt-3">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/corinne-callaway.jpg"
          alt="Corinne Callaway"
        />
        <div class="card-body">
          <h5 class="card-title">Social Secretary – Corinne Callaway</h5>
          <p class="card-text">
            <a href="mailto:info@readingjoggers.org">info@readingjoggers.org</a>
          </p>
          <p class="card-text">
            Hi there, my name is Corinne and I'm proud to announce myself as
            your Social Secretary. Aswell as a huge passion for running and a
            zest for organising social events, I enjoy spa days, afternoon tea,
            travelling and immersing myself in new experiences.
          </p>
          <p class="card-text">
            I’ve enjoyed many highlights from my past six years of running and
            now want to achieve new goals, to enable this I became a member of
            the club in January 2020. So far I’ve thoroughly enjoyed attending
            the sessions and have hugely progressed in the sport. I’ve also met
            some great and inspirational people.
          </p>
          <p class="card-text">
            If you enjoy running at any level and want to meet people who share
            the same passion, we would love you to join us. Everybody is welcome
            to join our fun, fab and friendly running club. So what are you
            waiting for!
          </p>
        </div>
      </div>
      <div class="card">
        <img
          class="card-img-top"
          src="images/committee/kirsty-moulsley.jpg"
          alt="Kirsty Moulsley"
        />
        <div class="card-body">
          <h5 class="card-title">Membership Secretary – Kirsty Moulsley</h5>
          <p class="card-text">
            <a href="mailto:membership@readingjoggers.org"
              >membership@readingjoggers.org</a
            >
          </p>
          <p class="card-text">
            I love running, simple! I’m excited to have joined the committee to
            give something back to Reading Joggers - a club that has given me so
            much… a love for running in the woods…the support to train and run
            the London Marathon, something I always thought was totally out of
            reach, the opportunity to run for 24 hours in Wasing Park… I could
            go on! Reading Joggers is a fab club, one I’m proud to be a part of
            and I’m looking forward to processing all your membership documents!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col">
      <div class="card-deck">
        <div class="card">
          <img
            class="card-img-top"
            src="images/committee/quentin-gunderson.jpg"
            alt="Quentin Gunderson"
          />
          <div class="card-body">
            <h5 class="card-title">Deputy Club Captain – Quentin Gunderson</h5>
            <p class="card-text">
              <a href="mailto:info@readingjoggers.org"
                >info@readingjoggers.org</a
              >
            </p>
            <p>
              Since taking up running in 2011, I have found running to be such a
              life-enhancer. I've been a member of Reading Joggers since 2015
              and love being part of this fun and friendly club. The club has
              also helped me reach some of my running goals and I would
              recommend you to come along whether you’re starting on a couch to
              5k or looking for qualifying times for a Major Marathon. I hope to
              see you soon!
            </p>
          </div>
        </div>
        <div class="card">
          <!-- <img
            class="card-img-top"
            src="images/committee/barry-tucker.jpg"
            alt="Barry Tucker"
          /> -->
          <div class="card-body">
            <h5 class="card-title">Club Welfare Officer – Barry Tucker</h5>
            <p class="card-text">
              <a href="mailto:info@readingjoggers.org"
                >info@readingjoggers.org</a
              >
            </p>
            <p>
              I have been a member of Reading Joggers now for over 6 years. I
              love being part of the club, so I decided to give something back.
              Being the welfare officer is a very important role and I feel
              privileged to hold this post.
            </p>
            <p>
              I have always been an active person for as long as I can remember,
              running, biking and hiking are my main passions in life. I have
              been running on and off for many years but have been more devoted
              to it in the past few years. I love training for events and
              setting myself challenges that I try and beat. I have completed
              many half marathons and 10k events but I am always on the lookout
              for new challenges. I love running as there is always something to
              test yourself against and a never ending bucket list of things to
              do. Happy running! Barry
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
